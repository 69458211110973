.map-history-visualizer-map-selector-container {
    align-items: center;
    justify-content: space-between;
    display: flex;
    height: 3rem;
    margin-bottom: 1rem;
}

.map-history-visualizer-button {
    background: #144066;
    border: none;
    border-radius: 0.625rem;
    color: #FAFEFF;
    cursor: pointer;
    font-family: Rubik, sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    height: 2.75rem;
    margin-bottom: 0.375rem;
    text-transform: uppercase;
}

.map-history-visualizer-svg-container {
    height: 43rem;
    width: 80rem;
    user-select: none;
}